import { cn, storeAssigned } from "@/services/Methods/normalMethods";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import _ from "lodash";
import Link from "next/link";
import React from "react";

const TextFieldComponent = ({
  label,
  value,
  type = 'text',
  defaultValue,
  readOnly = false,
  name,
  required = false,
  onChange,
  errorMessage = "",
  error = false,
  classes,
  divClass,
  inputPar,
  cstmError,
  options = [],
  inputMode,
  pattern,
  maxLength,
  ...props
}) => {

  return (
    <div className={cn(`w-[calc(100%/2-8px)] xl:mb-[24px] mb-[24px]`, divClass)}>
      <div className={cn(`relative ${name === "password" && 'passIcon'}`, inputPar)}>
        {type === "select" ? (
          <>
            <select
              name={name}
              id={name}
              value={value}
              onChange={onChange}
              className={cn(
                "border-clr border-[1px] pt-2 pb-1 block w-full font-medium text-base rounded-[6px] focus-visible:outline-none focus-visible:border-blue h-[54px]",
                "appearance-none",
                "pr-8",
                classes
              )}
              style={{
                WebkitAppearance: "none",
                paddingTop: "1rem",
                textIndent: "0.5rem",
                backgroundImage: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='black'><path d='M7 7l3 3 3-3'/></svg>")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "calc(100% - 0.5rem) center",
                backgroundSize: "1rem",
              }}
              {...props}
            >
              <option value="" disabled>
                Select an option
              </option>
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </>
        ) : (
          <input
            type={type}
            name={name}
            id={name}
            readOnly={readOnly}
            placeholder=""
            onChange={onChange}
            value={pattern ? value?.replace(/[^0-9]/g, '') : value}
            inputMode={inputMode}
            pattern={pattern}
            maxLength={maxLength}
            className={cn(
              "border-clr border-[1px] px-3.5 pt-6 pb-1 block w-full font-medium placeholder:text-black text-base rounded-[6px] focus-visible:outline-none focus-visible:border-blue",
              classes
            )}
            {...props}
          />
        )}

        {name === "password" &&
          (props?.showPass ? (
            <Visibility onClick={props?.onEyeClick} />
          ) : (
            <VisibilityOff onClick={props?.onEyeClick} />
          ))}

        <label
          htmlFor={name}
          className="absolute text-black text-base font-medium peer-focus:!font-medium peer-focus:!text-base duration-300 top-[0.3rem] z-10 origin-[0] start-3.5 pointer-events-none"
        >
          {label}
        </label>
      </div>


      {
        errorMessage && (
          <p className={cn(`mt-1 text-sm text-red-500`, cstmError)}>
            {_.includes(errorMessage, 'already exists') ? (
              <>
                Hey, I see you already have an account, would you like to &nbsp;
                <Link href={`/${storeAssigned()}/signin`} className="text-[#000]">log in?</Link>
              </>
            ) : (
              errorMessage
            )}
          </p>
        )
      }
    </div >
  );
};

export default TextFieldComponent;
